import type { FC } from "react";
import type { Candidate } from "../../interfaces/candidate.interface";
import { useJobHook } from "../../api/useJobHook.ts";

export const CandidateInfo: FC<{ candidate: Candidate }> = ({ candidate }) => {
  const { data: job, isSuccess: isSuccessJob } = useJobHook(candidate?.job_id);
  return (
    <div className="bg-white rounded-lg shadow p-6 mb-6">
      <h2 className="text-2xl font-semibold mb-4">{candidate.name}</h2>
      <div className="grid grid-cols-2 gap-4 text-gray-600">
        <div>
          <p>
            <span className="font-medium">ID:</span> {candidate.id}
          </p>
          <p>
            <span className="font-medium">Vendor ID:</span> {candidate.vendorId}
          </p>
          <p>
            <span className="font-medium">Driver:</span> {candidate.driver}
          </p>
          <p>
            <span className="font-medium">Processed:</span> {candidate.processed ? "YES" : "NO"}
          </p>
        </div>
        <div>
          <p>
            <span className="font-medium">Pipeline Step:</span> {candidate.pipeline_step}
          </p>
          <p>
            <span className="font-medium">Created:</span> {new Date(candidate.created_at).toLocaleDateString()},{" "}
            {new Date(candidate.created_at).toLocaleTimeString()}
          </p>
          <p>
            <span className="font-medium">Status:</span> {candidate.processed ? "Processed" : "Pending"}
          </p>
        </div>
        {isSuccessJob && (
          <div>
            <span className="font-medium">Applied to:</span> {job.name}
          </div>
        )}
      </div>
    </div>
  );
};
