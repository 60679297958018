import { Keys } from "./keys.ts";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import type { Candidate } from "../interfaces/candidate.interface.ts";

export const useCandidateHook = (id?: string) => {
  return useQuery<Candidate>({
    queryKey: [Keys.CANDIDATES, id],
    queryFn: async () => {
      const res = await axios.get<Candidate>(`/api/candidates/${id}`);
      return res.data;
    },
    enabled: !!id,
  });
};
