// src/components/form/Checkbox.tsx
import type { FC } from "react";
import type { UseFormRegister } from "react-hook-form";
import { v6 as uuid } from "uuid";

interface Props {
  label: string;
  name: string;
  register: UseFormRegister<any>;
  description?: string;
  className?: string;
}

export const Checkbox: FC<Props> = ({ label, name, register, description, className }) => {
  const id = `${uuid()}-${name}`;
  return (
    <div className={`relative flex items-start ${className}`}>
      <div className="flex h-6 items-center">
        <input
          type="checkbox"
          id={id}
          {...register(name)}
          aria-describedby={description ? `${id}-description` : undefined}
          className="size-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
        />
      </div>
      <div className="ml-3 text-sm/6">
        <label htmlFor={id} className="font-medium text-gray-900">
          {label}
        </label>
        {description && (
          <p id={`${id}-description`} className="mt-2 text-sm text-gray-500">
            {description}
          </p>
        )}
      </div>
    </div>
  );
};
