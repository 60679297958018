import type { FC } from "react";
import { useCandidateAssessHook } from "../api/useCandidateAssessHook.ts";
import { useParams } from "react-router-dom";
import { LoaderSpinner } from "../components/loader/loaderSpinner.component.tsx";
import { CandidateInfo } from "../views/candidate/candidateInfo.view.tsx";
import { ActionButtons } from "../views/candidate/actionButtons.view.tsx";
import { AssessmentCard } from "../views/candidate/assessmentCard.view.tsx";
import { useCandidateHook } from "../api/useCandidateHook.ts";
import { useCVAssessmentMutationHook } from "../api/useCVAssessmentMutationHook.ts";
import { toast } from "react-toastify";
import { usePhoneCallMutationHook } from "../api/usePhoneCallMutationHook.ts";

export const CandidatePage: FC = () => {
  const { id: candidateId } = useParams();
  const { data: assessments, isLoading } = useCandidateAssessHook(candidateId);
  const { data: candidate, isLoading: isCandidateLoading } = useCandidateHook(candidateId);

  const { mutate: mutateTriggerCVAssessment } = useCVAssessmentMutationHook({
    //, isSuccess: isTriggerCVAssessmentSuccess, isPending: isTriggerCVAssessmentPending
    candidateId: candidate?.id,
    onError: (error) => {
      toast.error(error.message || "Failed to do cv assessment");
    },
    onSuccess: () => {
      toast.success("CV Assessment Done");
    },
  });

  const { mutate: mutateTriggerPhoneCall } = usePhoneCallMutationHook({
    candidateId: candidate?.id,
    onError: (error) => {
      toast.error(error.message || "Failed to do phone call");
    },
    onSuccess: () => {
      toast.success("Phone Call Done");
    },
  });

  const handleReassess = (step: string) => {
    // TODO: Implement reassess functionality
    console.log("Reassessing candidate:", candidateId, step, candidate?.job_id);
    toast.info("CV Assessment Triggered. WAIT.");
    mutateTriggerCVAssessment();
  };

  const handleClear = () => {
    // TODO: Implement clear assessments functionality
    console.log("Clearing assessments for:", candidateId);
  };

  const handlePushToVendor = () => {
    // TODO: Implement push to vendor functionality
    console.log("Pushing to vendor:", candidateId);
  };

  const handleMakeACall = () => {
    console.log("Making a call to:", candidateId);
    toast.info("Phone Call Triggered. WAIT.");
    mutateTriggerPhoneCall();
  };

  if (isLoading || isCandidateLoading) {
    return (
      <main className="mx-auto max-w-7xl pb-12 px-4 sm:px-6 lg:px-8">
        <div className="flex justify-center items-center h-64">
          <LoaderSpinner />
        </div>
      </main>
    );
  }

  return (
    <main className="mx-auto max-w-7xl pb-12 px-4 sm:px-6 lg:px-8">
      {candidate && <CandidateInfo candidate={candidate} />}

      <ActionButtons
        onReassess={handleReassess}
        onClear={handleClear}
        onPushToVendor={handlePushToVendor}
        onMakeACall={handleMakeACall}
        candidate={candidate}
      />

      <div className="space-y-4">
        <h2 className="text-xl font-semibold mb-4">Assessments</h2>

        <dl className="space-y-6 divide-y divide-gray-900/10">
          {assessments && assessments.length > 0 ? (
            assessments.map((assessment) => <AssessmentCard key={assessment.id} assessment={assessment} />)
          ) : (
            <div className="bg-gray-50 rounded-lg p-8 text-center text-gray-500">
              No assessments available for this candidate.
            </div>
          )}
        </dl>
      </div>
    </main>
  );
};
