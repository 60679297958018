import type { FC } from "react";
import { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { RoutesConstants } from "../constants/routes.constants.ts";
import { useCandidatesListHook } from "../api/useCandidatesListHook.ts";
import { useJobsListHook } from "../api/useJobsListHook.ts";
import { PaginationControls } from "../components/pagination/paginationControls.component.tsx";
import { usePagination } from "../components/pagination/usePagination.hook.ts";
import Skeleton from "react-loading-skeleton";
import { useStableDebounce } from "../utils/useStableDebounce.hook.ts";
import { Input } from "../components/form/Input.tsx";
import { Select } from "../components/form/Select.tsx";
import { useForm } from "react-hook-form";
import { Checkbox } from "../components/form/Checkbox.tsx";

interface SearchForm {
  search: string;
  jobId: string;
  processed: boolean;
}

export const CandidatesPage: FC = () => {
  const { page, size, setPage } = usePagination();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [selectedJobId, setSelectedJobId] = useState<string>("");
  const [filterProcessed, setFilterProcessed] = useState<boolean>(true);

  const { register, watch, reset } = useForm<SearchForm>({
    defaultValues: {
      search: "",
      jobId: "",
      processed: true,
    },
  });

  // Fetch jobs list
  const { data: jobs } = useJobsListHook();

  const updateSearch = useCallback(
    (value: string) => {
      setDebouncedSearchTerm(value);
      setPage(0); // Reset to first page when search changes
    },
    [setPage],
  );

  // Watch for job changes
  const watchedJobId = watch("jobId");
  // Update selected job and reset pagination when job changes
  useStableDebounce(
    (value: string) => {
      setSelectedJobId(value);
      setPage(0);
    },
    500,
    watchedJobId,
  );

  const watchedProcessed = watch("processed");
  useStableDebounce(
    (value: boolean) => {
      setFilterProcessed(value);
      setPage(0);
    },
    500,
    watchedProcessed,
  );

  // Use the stable debounce hook correctly for search term
  useStableDebounce(updateSearch, 500, watch("search"));

  const { data: candidates, isLoading } = useCandidatesListHook({
    page,
    size,
    search: debouncedSearchTerm,
    jobId: selectedJobId,
    processed: filterProcessed,
  });

  const handleClear = useCallback(() => {
    reset({ search: "", jobId: "" });
    setDebouncedSearchTerm("");
    setSelectedJobId("");
    setFilterProcessed(false);
    setPage(0);
  }, [reset, setPage]);

  const skeletonRows = Array(size).fill(null);

  // Prepare jobs options for the select
  const jobOptions =
    jobs?.jobs.map((job) => ({
      value: job.id.toString(),
      label: job.name,
    })) ?? [];

  return (
    <main>
      <div className="mx-auto max-w-7xl pb-12 sm:px-6 lg:px-8 px-4">
        <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
          {/* Search Inputs */}
          <div className="flex flex-row items-end w-full gap-4 mb-4">
            <Input label="Search candidates" name="search" register={register} type="text" className="grow" />
            <Select
              label="Filter by Job"
              name="jobId"
              register={register}
              options={jobOptions}
              emptyOption="All Jobs"
              className="grow"
            />
            <Checkbox label="Only processed" name="processed" register={register} className="mb-[6px]" />
            <button
              type="button"
              onClick={handleClear}
              className="rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Clear filters
            </button>
          </div>

          <ul className="divide-y divide-gray-100">
            {isLoading
              ? skeletonRows.map((_, index) => <CandidateSkeletonRow key={`skeleton-${index.toString()}`} />)
              : candidates?.content?.map((candidate) => (
                  <li key={candidate.id} className="flex items-center justify-between gap-x-6 py-5">
                    <div className="min-w-0">
                      <div className="flex items-center gap-x-3">
                        <span className="text-sm/6 font-semibold text-gray-900">
                          <Link to={`${RoutesConstants.CANDIDATES}/${candidate.id}`}>{candidate.name}</Link>
                          <span className="flex gap-4 font-light">
                            <span>Step: {candidate.pipeline_step}</span>
                            <span>|</span>
                            <span>Processed: {candidate.processed ? "Yes" : "No"}</span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </li>
                ))}
          </ul>

          {/* Pagination Controls */}
          {candidates && <PaginationControls metadata={candidates.metadata} onPageChange={setPage} className="mt-4" />}
        </div>
      </div>
    </main>
  );
};

const CandidateSkeletonRow: FC = () => (
  <li className="flex items-center justify-between gap-x-6 py-5">
    <div className="min-w-0">
      <div className="flex items-center gap-x-3">
        <Skeleton width={200} height={24} />
      </div>
    </div>
  </li>
);
