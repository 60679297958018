import { SortableContentBuilder } from "../components/form/SortableContentBuilder/SortableContentBuilder.component.tsx";
import type { ContentItemInterface } from "../components/form/SortableContentBuilder/SortableContentBuilder.types.ts";
import type { FieldError } from "react-hook-form";

export const PromptPage = () => {
  const handleChange = (items: ContentItemInterface[]) => {
    console.log("Updated items:", items);
  };

  const handleError = (error: FieldError) => {
    console.error("Error:", error);
  };

  return (
    <main>
      <div className="mx-auto max-w-7xl pb-12 sm:px-6 lg:px-8 px-4 space-y-12">
        <div className="pt-6 pb-3">
          <h2 className="text-2xl">Master prompt</h2>
          <p className="mt-2 text-gray-500">Train AI Agent on how it is supposed to behave.</p>
          <p className="mt-2 text-gray-500">
            <span className="font-black">Variables:</span>
            <span className="text-gray-400">
              {"{{candidateName}}"}, {"{{jobTitle}}"}, {"{{jobDetailsJsonString}}"}, {"{{promptComment}}"},{" "}
              {"{{promptAssessment}}"}, {"{{strictnessLevel}}"}, {"{{currentDateTime}}"}
            </span>
          </p>
        </div>
        <SortableContentBuilder
          value={[
            {
              id: "1",
              title: "First Item",
              content: "Some content",
              enabled: true,
              order: 0,
            },
            // ... more items
          ]}
          onChange={handleChange}
          onError={handleError}
        />
      </div>
    </main>
  );
};
