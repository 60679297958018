import type { FC } from "react";

export const CompanyPage: FC = () => {
  return (
    <main>
      <div className="mx-auto max-w-7xl pb-12 sm:px-6 lg:px-8 px-4 space-y-12">
        <div className="pt-6 pb-3">
          <h2 className="text-2xl">Company information</h2>
          <p className="mt-2 text-gray-500">Train AI Agent about your company, business, and culture.</p>
        </div>

        <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">Coming soon...</div>
      </div>
    </main>
  );
};
