import { Keys } from "./keys.ts";
import axios, { type AxiosError, type AxiosResponse } from "axios";
import { useMutation } from "@tanstack/react-query";
import type { ResponseErrorInterface } from "../interfaces/ResponseError.interface.ts";
import type { JobSettings } from "../interfaces/jobSettings.interface.ts";
import type { TJobSettings } from "../views/jobGlobalSettings.view.tsx";

interface Props {
  jobId?: string;
  onError: (error: AxiosError<ResponseErrorInterface>) => void;
  onSuccess: () => void;
}

export const useJobSettingsMutationHook = ({ jobId, onError, onSuccess }: Props) => {
  return useMutation({
    mutationKey: [Keys.JOB_SETTINGS_MUTATION],
    mutationFn: async (body: TJobSettings) => {
      const res = await axios<TJobSettings, AxiosResponse<JobSettings>>({
        data: {
          candidate_language: body.candidate_language,
          ats_language: body.ats_language,
          activity_country: body.activity_country,
          activity_timezone: body.activity_timezone,
          activity_hour_start: body.activity_hour_start,
          activity_hour_end: body.activity_hour_end,
          member_id: body.member_id,
        },
        url: `/api/job-settings/${jobId}`,
        method: "put",
      });
      return res.data;
    },
    onError: (error: AxiosError<ResponseErrorInterface>) => onError(error),
    onSuccess,
  });
};
