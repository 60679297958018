import type { FC } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Switch } from "../Switch";
import { TextArea } from "../TextArea";
import { Input } from "../Input";
import { useStableDebounce } from "../../../utils/useStableDebounce.hook";
import type { ContentItemInterface } from "./SortableContentBuilder.types";
import { contentItemSchema } from "../../../schema/contentItem.schema";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import { PlusIcon, MinusIcon, TrashIcon } from "@heroicons/react/24/outline";

interface ContentItemProps {
  item: ContentItemInterface;
  onUpdate: (id: string, updates: Partial<ContentItemInterface>) => void;
  onDelete: (id: string) => void;
}

export const ContentItem: FC<ContentItemProps> = ({ item, onUpdate, onDelete }) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(contentItemSchema),
    defaultValues: {
      title: item.title,
      content: item.content,
      enabled: item.enabled,
    },
    mode: "onBlur",
  });

  const formValues = watch();
  useStableDebounce(
    (values) => {
      if (values.title !== item.title || values.content !== item.content || values.enabled !== item.enabled) {
        onUpdate(item.id, values);
      }
    },
    500,
    formValues,
  );

  return (
    <div
      className={`group relative rounded-r-lg border-0 ${formValues.enabled ? "bg-white" : "bg-gray-100 opacity-50"}`}
    >
      <Disclosure>
        {({ open }) => (
          <>
            <div className="flex items-center justify-between px-4 pt-1 pb-3">
              <div className="flex-1 mr-2">
                <Input
                  type="text"
                  name="title"
                  register={register}
                  error={errors.title}
                  placeholder="Optional title"
                  className="w-full border-0 p-0 focus:ring-0 bg-transparent"
                />
              </div>

              <div className="flex items-center gap-2 pt-1">
                <div className="pt-1">
                  <Switch label="" name="enabled" register={register} watch={watch} />
                </div>

                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (confirm("Do you really want to delete this prompt?")) {
                      onDelete(item.id);
                    }
                  }}
                  className="rounded text-gray-400 hover:bg-gray-100 hover:text-gray-600 mt-[3px]"
                >
                  <TrashIcon className="h-5 w-5" />
                </button>

                <DisclosureButton className="ml-2 mt-0 focus:outline-none">
                  <PlusIcon className={`h-5 w-5 ${open ? "hidden" : ""}`} />
                  <MinusIcon className={`h-5 w-5 ${!open ? "hidden" : ""}`} />
                </DisclosureButton>
              </div>
            </div>
            {/*{errors.title && <p className="mt-1 text-sm text-red-600 px-4">{errors.title.message}</p>}*/}

            <DisclosurePanel className="px-4 pb-3">
              <TextArea
                name="content"
                register={register}
                error={errors.content}
                rows={3}
                placeholder="Enter the content for this item"
                className="mt-2"
              />
              {/*{errors.content && <p className="mt-1 text-sm text-red-600">{errors.content.message}</p>}*/}
            </DisclosurePanel>
          </>
        )}
      </Disclosure>
    </div>
  );
};
