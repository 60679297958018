import * as yup from "yup";

export const jobSettingsSchema = yup.object({
  // Language Settings
  candidate_language: yup.string().min(1, "Candidate language is required").required(),
  ats_language: yup.string().min(1, "ATS language is required").required(),
  member_id: yup.string().min(1, "Job Member needed").required(),

  // Activity Settings
  activity_country: yup.string().min(1, "Country is required").required(),
  activity_timezone: yup.string().min(1, "Timezone is required").required(),
  activity_hour_start: yup
    .number()
    .min(0, "Start hour must be between 0 and 23")
    .max(23, "Start hour must be between 0 and 23")
    .required(),
  activity_hour_end: yup
    .number()
    .min(0, "End hour must be between 0 and 23")
    .max(23, "End hour must be between 0 and 23")
    .test("is-greater-than-start", "End hour must be greater than start hour", function (value) {
      const { activity_hour_start } = this.parent;
      return value! > activity_hour_start;
    })
    .required(),
});

// TypeScript type inference
export type TJobSettings = yup.InferType<typeof jobSettingsSchema>;
