import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import type { JobMemberInterface } from "../interfaces/jobMember.interface.ts";

export const useJobMembersHook = (id?: string) => {
  return useQuery<JobMemberInterface[]>({
    queryKey: ["job-members", id],
    queryFn: async () => {
      const res = await axios.get<JobMemberInterface[]>(`/api/jobs/${id}/members`);
      return res.data;
    },
    enabled: !!id,
  });
};
