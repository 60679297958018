import type { FC } from "react";
import type { AssessmentInterface } from "../../interfaces/assessment.interface";
import ReactMarkdown from "react-markdown";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import { PlusIcon, MinusIcon } from "@heroicons/react/24/outline";

export const AssessmentCard: FC<{ assessment: AssessmentInterface }> = ({ assessment }) => {
  return (
    <Disclosure as="div" className="bg-white">
      <dt className="flex flex-row p-4 border-b">
        <div className="w-full grow flex flex-row gap-4 items-center">
          <h3 className="text-xl font-medium ml-2">{assessment.assessment_name}</h3>{" "}
          <div className="text-sm text-gray-500 mt-[3px]">{new Date(assessment.updated_at).toLocaleString()}</div>
          <div className="flex grow" />
          <span
            className={`px-3 py-1 rounded-full ${
              assessment.score >= 75
                ? "bg-green-100 text-green-800"
                : assessment.score >= 50
                  ? "bg-yellow-100 text-yellow-800"
                  : "bg-red-100 text-red-800"
            }`}
          >
            Score: {assessment.score}%
          </span>
        </div>
        <DisclosureButton className="group flex justify-between text-left text-gray-900 items-center">
          <span className="ml-6 flex h-7 items-center">
            <PlusIcon aria-hidden="true" className="size-6 group-data-[open]:hidden" />
            <MinusIcon aria-hidden="true" className="size-6 [.group:not([data-open])_&]:hidden" />
          </span>
        </DisclosureButton>
      </dt>
      <DisclosurePanel as="dd" className="mt-2 px-0 py-2">
        <div className="p-6 pt-0 space-y-6 markdown-text">
          <div className="text-gray-700">
            <h4 className="font-medium mb-1">Assessment Content:</h4>
            <ReactMarkdown className="bg-gray-100 border-2 border-gray-300 p-4 rounded-2xl">
              {assessment.content}
            </ReactMarkdown>
          </div>

          {assessment.comment && (
            <div className="text-gray-700">
              <h4 className="font-medium mb-1">Comments:</h4>
              <ReactMarkdown className="bg-gray-100 border-2 border-gray-300 p-4 rounded-2xl">
                {assessment.comment}
              </ReactMarkdown>
            </div>
          )}

          {assessment.things_missing && (
            <div className="text-gray-700">
              <h4 className="font-medium mb-1">Missing Elements:</h4>
              <ReactMarkdown className="bg-gray-100 border-2 border-gray-300 p-4 rounded-2xl">
                {assessment.things_missing}
              </ReactMarkdown>
            </div>
          )}
        </div>
      </DisclosurePanel>
    </Disclosure>

    // <div className="bg-white rounded-lg shadow mb-4 hover:shadow-lg transition-shadow">
    //   {/* Header - Always visible */}
    //   {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
    //   <div className="p-4 cursor-pointer flex items-center justify-between" onClick={() => setIsExpanded(!isExpanded)}>
    //     <div className="flex items-center space-x-4 flex-1">
    //       <div className="flex items-center">
    //         {/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
    //         <svg
    //           className={`w-5 h-5 transform transition-transform ${isExpanded ? "rotate-180" : ""}`}
    //           fill="none"
    //           stroke="currentColor"
    //           viewBox="0 0 24 24"
    //         >
    //           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
    //         </svg>
    //         <h3 className="text-xl font-medium ml-2">{assessment.assessment_name}</h3>
    //       </div>
    //       <div className="text-sm text-gray-500">{new Date(assessment.updated_at).toLocaleString()}</div>
    //     </div>
    //     <span
    //       className={`px-3 py-1 rounded-full ${
    //         assessment.score >= 75
    //           ? "bg-green-100 text-green-800"
    //           : assessment.score >= 50
    //             ? "bg-yellow-100 text-yellow-800"
    //             : "bg-red-100 text-red-800"
    //       }`}
    //     >
    //       Score: {assessment.score}%
    //     </span>
    //   </div>
    //
    //   {/* Expandable Content */}
    //   <div
    //     className={`overflow-hidden transition-all duration-300 ease-in-out ${
    //       isExpanded ? "max-h-auto opacity-100" : "max-h-0 opacity-0"
    //     }`}
    //   >
    //     <div className="p-6 pt-0 space-y-3 border-t">
    //       <div className="text-gray-700">
    //         <h4 className="font-medium mb-1">Assessment Content:</h4>
    //         <ReactMarkdown className="whitespace-pre-wrap bg-gray-100 border-2 border-gray-300 p-4 rounded-2xl">
    //           {assessment.content}
    //         </ReactMarkdown>
    //       </div>
    //
    //       {assessment.comment && (
    //         <div className="text-gray-700">
    //           <h4 className="font-medium mb-1">Comments:</h4>
    //           <ReactMarkdown className="whitespace-pre-wrap bg-gray-100 border-2 border-gray-300 p-4 rounded-2xl">
    //             {assessment.comment}
    //           </ReactMarkdown>
    //         </div>
    //       )}
    //
    //       {assessment.things_missing && (
    //         <div className="text-gray-700">
    //           <h4 className="font-medium mb-1">Missing Elements:</h4>
    //           <ReactMarkdown className="whitespace-pre-wrap bg-gray-100 border-2 border-gray-300 p-4 rounded-2xl">
    //             {assessment.things_missing}
    //           </ReactMarkdown>
    //         </div>
    //       )}
    //     </div>
    //   </div>
    // </div>
  );
};
