import type { FC } from "react";
import { useParams } from "react-router-dom";
import { useCountriesHook } from "../api/useCountriesHook.ts";
import { useJobHook } from "../api/useJobHook.ts";
import { useJobPipelineStepsHook } from "../api/useJobPipelineStepsHook.ts";
import { useJobSettingsHook } from "../api/useJobSettingsHook.ts";
import { useLanguagesHook } from "../api/useLanguagesHook.ts";
import { LoaderSpinner } from "../components/loader/loaderSpinner.component.tsx";
import type { WorkflowConfigForm } from "../views/workflowConfigurator.view.tsx";
import { WorkflowConfiguratorView } from "../views/workflowConfigurator.view.tsx";
import { JobGlobalSettingsView } from "../views/jobGlobalSettings.view.tsx";
import type { TJobSettings } from "../views/jobGlobalSettings.view.tsx";
import { useJobSettingsMutationHook } from "../api/useJobSettingsMutationHook.ts";
import { toast } from "react-toastify";
import { useJobSettingsWorkflowConfigMutationHook } from "../api/useJobSettingsWorkflowConfigMutationHook.ts";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import { PlusIcon, MinusIcon } from "@heroicons/react/24/outline";
import { useJobMembersHook } from "../api/useJobMembersHook.ts";

export const JobSettingsPage: FC = () => {
  const { id: jobId } = useParams();

  const { data: job, isLoading: isJobLoading } = useJobHook(jobId);
  const {
    data: jobSettings,
    isLoading: isJobSettingsLoading,
    isSuccess: isSuccessJobSettings,
  } = useJobSettingsHook(jobId);

  const { data: countries, isSuccess: isSuccessCountries } = useCountriesHook({});
  const { data: languages, isSuccess: isSuccessLanguages } = useLanguagesHook({});
  const { data: pipelineSteps, isSuccess: isSuccessPipelineSteps } = useJobPipelineStepsHook(jobId);
  const { data: jobMembers, isSuccess: isSuccessJobMembers } = useJobMembersHook(jobId);

  const { mutate: mutateJobSettings } = useJobSettingsMutationHook({
    jobId,
    onError: (error) => {
      toast.error(error.message);
    },
    onSuccess: () => {
      toast.success("Settings updated successfully!");
    },
  });

  const { mutate: mutateJobSettingsWorkflowConfig } = useJobSettingsWorkflowConfigMutationHook({
    jobId,
    onError: (error) => {
      toast.error(error.message);
    },
    onSuccess: () => {
      toast.success("Workflow configuration updated successfully!");
    },
  });

  const onChangeWorkflowConfig = (data: WorkflowConfigForm) => {
    // console.log(data);
    mutateJobSettingsWorkflowConfig(data);
  };

  const onChangeJobGlobalSettings = (data: TJobSettings) => {
    // console.log(data);
    mutateJobSettings(data);
  };

  if (
    isJobLoading ||
    isJobSettingsLoading ||
    !jobSettings ||
    !isSuccessJobSettings ||
    !isSuccessCountries ||
    !isSuccessLanguages ||
    !isSuccessPipelineSteps ||
    !isSuccessJobMembers ||
    !job
  ) {
    return <LoaderSpinner />;
  }

  return (
    <main>
      <div className="mx-auto max-w-7xl pb-12 sm:px-6 lg:px-8 px-4 space-y-12">
        <div className="pt-6 pb-3">
          <h2 className="text-2xl">
            Settings for: <span>{job!.name}</span>
          </h2>
          <p className="mt-2 text-gray-500">
            These settings allow you to configure and customize how the AI Recruiter automates key stages of the hiring
            process. Each section focuses on a specific aspect of recruitment, such as assessing CVs, verifying skills,
            conducting phone screenings, or managing candidate interaction preferences. By defining triggers, actions,
            and outcomes, you can tailor the automation to align with your recruitment strategy.
          </p>
        </div>

        <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
          <dl className="space-y-6 divide-y divide-gray-900/10">
            <Disclosure key={job.id} as="div" className="">
              <dt>
                <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
                  <span className="text-base/7 font-semibold">Job Details</span>
                  <span className="ml-6 flex h-7 items-center">
                    <PlusIcon aria-hidden="true" className="size-6 group-data-[open]:hidden" />
                    <MinusIcon aria-hidden="true" className="size-6 [.group:not([data-open])_&]:hidden" />
                  </span>
                </DisclosureButton>
              </dt>
              <DisclosurePanel as="dd" className="mt-2 pr-12">
                <div className="text-xs text-gray-600">
                  <code>
                    <pre className="text-wrap	pl-3">{JSON.stringify(job.raw_details, null, 2)}</pre>
                  </code>
                </div>
              </DisclosurePanel>
            </Disclosure>
          </dl>
        </div>

        <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
          <JobGlobalSettingsView
            settings={jobSettings}
            onChange={onChangeJobGlobalSettings}
            onError={console.log}
            countries={countries}
            languages={languages}
            jobMembers={jobMembers}
          />
        </div>

        <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
          <WorkflowConfiguratorView
            name="config_cv_assessment"
            title="CV Assessment"
            description="This section enables the AI to assess candidates’ CVs based on predefined job criteria. The AI evaluates factors such as skills, experience, and alignment with the job description. Use this section to ensure only the most relevant CVs progress in the pipeline. You can configure triggers, set strictness levels, and define the actions to take based on assessment outcomes."
            config={jobSettings.config_cv_assessment}
            pipelineSteps={pipelineSteps}
            onChange={onChangeWorkflowConfig}
            onError={console.log}
          />
        </div>

        <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
          <WorkflowConfiguratorView
            name="config_email_skill_verification"
            title="Email Skill Verification"
            description="This section automates sending skill-verification emails to candidates. The AI drafts and sends emails based on your prompts and predefined skill criteria, collecting responses to assess accuracy and honesty. Use this feature to verify candidates’ claimed skills without manual intervention. Configure triggers, follow-up actions, and strictness levels to control how this automation behaves."
            config={jobSettings.config_email_skill_verification}
            pipelineSteps={pipelineSteps}
            onChange={onChangeWorkflowConfig}
            onError={console.log}
          />
        </div>

        <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
          <WorkflowConfiguratorView
            name="config_phone_screening"
            title="Phone Screening"
            description="The phone screening section automates initial candidate calls. The AI conducts structured phone interviews, evaluates responses, and transcribes critical insights. This section is ideal for gathering detailed information about candidates’ skills, experience, and cultural fit. You can define when to initiate phone screenings, configure outcomes based on results, and provide prompts for generating summaries of the call."
            config={jobSettings.config_phone_screening}
            pipelineSteps={pipelineSteps}
            onChange={onChangeWorkflowConfig}
            onError={console.log}
          />
        </div>
      </div>
    </main>
  );
};
