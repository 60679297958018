import * as yup from "yup";

export const contentItemSchema = yup.object({
  title: yup.string().optional(),
  content: yup.string().required("Content is required").min(1, "Content cannot be empty"),
  enabled: yup.boolean().required("Enabled status is required"),
});

// TypeScript type inference
export type TContentItemForm = yup.InferType<typeof contentItemSchema>;
