import type { FC } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useProfileHook } from "../api/useProfileHook";
import { useProfileMutationHook } from "../api/useProfileMutationHook";
import { toast } from "react-toastify";
import { Email } from "../components/form/Email";
import { Phone } from "../components/form/Phone";
import { useEffect } from "react";
import { Input } from "../components/form/Input.tsx";
import { TextArea } from "../components/form/TextArea.tsx";

// Define the form data type
interface ConfigurationFormData {
  email: string;
  phone: string;
  company_name: string;
  caller_agent_name: string;
  caller_agent_signature: string;
  caller_agent_title: string;
}

// Define the validation schema
const schema = yup.object().shape({
  email: yup.string().email("Please enter a valid email address").required("Email is required"),
  phone: yup
    .string()
    .matches(/^\+?[1-9]\d{1,14}$/, "Please enter a valid phone number with country code")
    .required("Phone number is required"),
  company_name: yup.string().required("Company name is required"),
  caller_agent_name: yup.string().required("Caller name is required"),

  caller_agent_signature: yup.string().required("Caller signature is required"),
  caller_agent_title: yup.string().required("Caller job title is required"),
});

export const ConfigurationPage: FC = () => {
  // Set up form with validation
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<ConfigurationFormData>({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  // Fetch profile data
  const { data: profile, isLoading: isLoadingProfile, error: profileError } = useProfileHook();

  // Set up mutation for updates
  const { mutate: mutateProfile, isPending: isUpdating } = useProfileMutationHook({
    onError: (error) => {
      toast.error(error.message || "Failed to update profile");
    },
    onSuccess: () => {
      toast.success("Profile updated successfully");
    },
  });

  // Initialize form with profile data when it's loaded
  useEffect(() => {
    if (profile) {
      reset({
        email: profile.email,
        phone: profile.phone ?? "",
        company_name: profile.company_name ?? "",
        caller_agent_name: profile.caller_agent_name ?? "",
        caller_agent_signature: profile.caller_agent_signature ?? "",
        caller_agent_title: profile.caller_agent_title ?? "",
      });
    }
  }, [profile, reset]);

  // Handle form submission
  const onSubmit = async (data: ConfigurationFormData) => {
    console.log("data", data);
    mutateProfile(data);
  };

  // Show loading state
  if (isLoadingProfile) {
    return (
      <main>
        <div className="mx-auto max-w-7xl pb-12 sm:px-6 lg:px-8 px-4">
          <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
            <div className="flex justify-center">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600" />
            </div>
          </div>
        </div>
      </main>
    );
  }

  // Show error state
  if (profileError) {
    return (
      <main>
        <div className="mx-auto max-w-7xl pb-12 sm:px-6 lg:px-8 px-4">
          <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
            <div className="text-center text-red-600">{profileError.message || "Failed to load profile"}</div>
          </div>
        </div>
      </main>
    );
  }

  return (
    <main>
      <div className="mx-auto max-w-7xl pb-12 sm:px-6 lg:px-8 px-4">
        <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
          <form onSubmit={handleSubmit(onSubmit)} className="mx-auto w-full max-w-md space-y-6">
            <Email
              label="Email"
              name="email"
              register={register}
              error={errors.email}
              description="This email will be used for notifications"
            />

            <Phone
              label="Phone"
              name="phone"
              register={register}
              error={errors.phone}
              description="Enter your phone number including country code"
            />

            <Input
              label="Caller name"
              name="caller_agent_name"
              register={register}
              error={errors.caller_agent_name}
              description="AI Agent who is making phone call name"
              type="text"
            />

            <Input
              label="Caller job title"
              name="caller_agent_title"
              register={register}
              error={errors.caller_agent_title}
              description="AI Agent job title"
              type="text"
            />

            <TextArea
              label="AI Agent email signature"
              name="caller_agent_signature"
              register={register}
              error={errors.caller_agent_signature}
              description="AI Agent email signature"
            />

            <Input
              label="Company name"
              name="company_name"
              register={register}
              error={errors.company_name}
              description="Company name that is hiring"
              type="text"
            />

            <div className="flex justify-end">
              <button
                type="submit"
                disabled={isSubmitting || isUpdating}
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isSubmitting || isUpdating ? (
                  <div className="flex items-center">
                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2" />
                    Saving...
                  </div>
                ) : (
                  "Save Changes"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
};
