import type { FC } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import type { ContentItemInterface } from "./SortableContentBuilder.types.ts";

interface SortableItemProps {
  id: string;
  item: ContentItemInterface;
  children: React.ReactNode;
}

export const SortableItem: FC<SortableItemProps> = ({ id, children }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} className={`relative ${isDragging ? "z-10" : ""}`}>
      <div
        {...attributes}
        {...listeners}
        className="absolute inset-y-0 left-0 w-8 cursor-move bg-gray-50 hover:bg-gray-100 rounded-l flex items-center justify-center"
      >
        {/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
        <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8h16M4 16h16" />
        </svg>
      </div>
      <div className="pl-8">{children}</div>
    </div>
  );
};
