import axios, { type AxiosError, type AxiosResponse } from "axios";
import { useMutation } from "@tanstack/react-query";
import type { ResponseErrorInterface } from "../interfaces/ResponseError.interface.ts";

interface Props {
  candidateId?: string;
  onError: (error: AxiosError<ResponseErrorInterface>) => void;
  onSuccess: () => void;
}

export const usePhoneCallMutationHook = ({ candidateId, onError, onSuccess }: Props) => {
  return useMutation({
    mutationKey: ["phone_call", candidateId],
    mutationFn: async () => {
      const res = await axios<void, AxiosResponse<any>>({
        data: {},
        url: `/api/candidates/${candidateId}/assessment/trigger/phone_call`,
        method: "put",
      });
      return res.data;
    },
    onError: (error: AxiosError<ResponseErrorInterface>) => onError(error),
    onSuccess,
  });
};
