import { Route, Routes } from "react-router-dom";
import { CallbackPage } from "./pages/callback.page.tsx";
import { DashboardPage } from "./pages/dashboard.page.tsx";
import { LayoutView } from "./views/layout.view.tsx";
import { PlatformPage } from "./pages/platform.page.tsx";
import { JobsPage } from "./pages/jobs.page.tsx";
import { RoutesConstants } from "./constants/routes.constants.ts";
import { JobSettingsPage } from "./pages/jobSettings.page.tsx";
import { CandidatesPage } from "./pages/candidates.page.tsx";
import "react-loading-skeleton/dist/skeleton.css";
import { ConfigurationPage } from "./pages/configuration.page.tsx";
import { CandidatePage } from "./pages/candidate.page.tsx";
import { ErrorPage } from "./pages/error.page.tsx";
import { CompanyPage } from "./pages/company.page.tsx";
import { PromptPage } from "./pages/prompt.page.tsx";

function App() {
  return (
    <LayoutView>
      <Routes>
        <Route path="/" element={<DashboardPage />} />
        <Route path="/platform/new" element={<PlatformPage />} />
        <Route path="/platform/:id" element={<PlatformPage />} />
        <Route path="/stuff" element={<>,,,,,,,,,</>} />
        <Route path="/configuration" element={<ConfigurationPage />} />
        <Route path="/company" element={<CompanyPage />} />
        <Route path="/prompt" element={<PromptPage />} />
        <Route path={RoutesConstants.JOBS} element={<JobsPage />} />
        <Route path={`${RoutesConstants.JOBS}/:id`} element={<JobSettingsPage />} />
        <Route path={RoutesConstants.CANDIDATES} element={<CandidatesPage />} />
        <Route path={`${RoutesConstants.CANDIDATES}/:id`} element={<CandidatePage />} />
        <Route path="/callback" element={<CallbackPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </LayoutView>
  );
}

export default App;
