import { type FC, useState, useCallback } from "react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  type DragEndEvent,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { v4 as uuid } from "uuid";
import { ContentItem } from "./ContentItem";
import { SortableItem } from "./SortableItem";
import type { ContentItemInterface, SortableContentBuilderProps } from "./SortableContentBuilder.types.ts";

export const SortableContentBuilder: FC<SortableContentBuilderProps> = ({ value = [], onChange }) => {
  const [items, setItems] = useState<ContentItemInterface[]>(value.sort((a, b) => a.order - b.order));

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (over && active.id !== over.id) {
        setItems((items) => {
          const oldIndex = items.findIndex((item) => item.id === active.id);
          const newIndex = items.findIndex((item) => item.id === over.id);

          const newItems = arrayMove(items, oldIndex, newIndex).map((item, index) => ({ ...item, order: index }));

          onChange?.(newItems);
          return newItems;
        });
      }
    },
    [onChange],
  );

  const handleAdd = useCallback(() => {
    const newItem: ContentItemInterface = {
      id: uuid(),
      content: "",
      enabled: true,
      order: items.length,
    };

    const newItems = [...items, newItem];
    setItems(newItems);
    onChange?.(newItems);
  }, [items, onChange]);

  const handleUpdate = useCallback(
    (id: string, updates: Partial<ContentItemInterface>) => {
      setItems((currentItems) => {
        const newItems = currentItems.map((item) => (item.id === id ? { ...item, ...updates } : item));
        onChange?.(newItems);
        return newItems;
      });
    },
    [onChange],
  );

  const handleDelete = useCallback(
    (id: string) => {
      setItems((currentItems) => {
        const newItems = currentItems
          .filter((item) => item.id !== id)
          .map((item, index) => ({ ...item, order: index }));
        onChange?.(newItems);
        return newItems;
      });
    },
    [onChange],
  );

  return (
    <div className="space-y-4">
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={items.map((item) => item.id)} strategy={verticalListSortingStrategy}>
          <div className="space-y-3">
            {items.map((item) => (
              <SortableItem key={item.id} id={item.id} item={item}>
                <ContentItem item={item} onUpdate={handleUpdate} onDelete={handleDelete} />
              </SortableItem>
            ))}
          </div>
        </SortableContext>
      </DndContext>

      <button
        type="button"
        onClick={handleAdd}
        className="inline-flex items-center gap-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        {/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
        <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
        </svg>
        Add Item
      </button>
    </div>
  );
};
