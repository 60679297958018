import { useState } from "react";
import type { FC } from "react";
import { Select } from "../../components/form/Select.tsx";
import { useForm } from "react-hook-form";
import { useStableDebounce } from "../../utils/useStableDebounce.hook.ts";
import { useJobPipelineStepsHook } from "../../api/useJobPipelineStepsHook.ts";
import type { Candidate } from "../../interfaces/candidate.interface.ts";
import { useProfileHook } from "../../api/useProfileHook.ts";

interface AssessmentForm {
  step: string;
}

export const ActionButtons: FC<{
  onReassess: (step: string) => void;
  onClear: () => void;
  onPushToVendor: () => void;
  onMakeACall: () => void;
  isLoading?: boolean;
  candidate?: Candidate;
}> = ({ onReassess, onMakeACall, isLoading, candidate }) => {
  const [step, setStep] = useState<string>("");

  const { data: pipelineSteps, isSuccess: isSuccessPipelineSteps } = useJobPipelineStepsHook(candidate?.job_id);

  const { data: profile, isLoading: isLoadingProfile } = useProfileHook();

  const pipelineStepOptions =
    pipelineSteps?.map((step) => ({
      value: step.name,
      label: step.name,
    })) ?? [];

  const { register, watch } = useForm<AssessmentForm>({
    defaultValues: {
      step: "",
    },
  });

  const watchedStep = watch("step");
  useStableDebounce(
    (value: string) => {
      setStep(value);
    },
    500,
    watchedStep,
  );

  const handleTriggerClick = () => {
    onReassess(step);
  };

  return (
    <div className="flex gap-3 mb-6 items-end">
      <div className="flex gap-2 items-end">
        {isSuccessPipelineSteps && (
          <Select
            label="Step entered"
            name="step"
            register={register}
            options={pipelineStepOptions}
            emptyOption="-- Select Step to Trigger"
            className="grow max-w-[260px]"
          />
        )}
        <button
          type="button"
          onClick={handleTriggerClick}
          disabled={isLoading}
          className="px-4 py-1/2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-blue-300 h-[36px]"
        >
          Trigger assessments
        </button>
      </div>
      {/*<button*/}
      {/*  type="button"*/}
      {/*  onClick={onClear}*/}
      {/*  disabled={isLoading}*/}
      {/*  className="px-4 py-1/2 bg-red-500 text-white rounded hover:bg-red-600 disabled:bg-red-300 h-[36px]"*/}
      {/*>*/}
      {/*  Clear All Assessments*/}
      {/*</button>*/}
      {!isLoadingProfile && profile?.phone && (
        <button
          type="button"
          onClick={onMakeACall}
          disabled={isLoading}
          className="px-4 py-1/2 bg-green-500 text-white rounded hover:bg-green-600 disabled:bg-green-300 h-[36px]"
        >
          Test Call {profile?.phone}
        </button>
      )}
    </div>
  );
};
