import { Keys } from "./keys.ts";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import type { Candidate } from "../interfaces/candidate.interface.ts";
import type { PageResponse } from "../interfaces/pageResponse.interface.ts";
import type { DefaultPaginatedListParams } from "../interfaces/defaultPaginatedListParams.interface.ts";
import { PAGE_SIZE } from "../constants/pagination.constants.ts";
import type { CandidateFilterableSearchableParams } from "../interfaces/candidateFilterableSearcheableParams.interface.ts";

export const useCandidatesListHook = ({
  // Pagination
  page = 0,
  size = PAGE_SIZE,
  sortBy,
  sortDirection,
  // Search
  search,
  jobId,
  driver,
  pipelineStep,
  processed,
}: DefaultPaginatedListParams & CandidateFilterableSearchableParams = {}) => {
  return useQuery<PageResponse<Candidate>>({
    queryKey: [Keys.CANDIDATES, page, size, sortBy, sortDirection, search, jobId, driver, pipelineStep, processed],
    queryFn: async () => {
      const params = new URLSearchParams({
        page: page.toString(),
        size: size.toString(),
        ...(sortBy && { sortBy }),
        ...(sortDirection && { sortDirection }),
        search: search?.toString() ?? "",
        jobId: jobId?.toString() ?? "",
        driver: driver?.toString() ?? "",
        pipelineStep: pipelineStep?.toString() ?? "",
        processed: processed ? "true" : "false",
      });

      const res = await axios.get<PageResponse<Candidate>>(`/api/candidates?${params.toString()}`);
      return res.data;
    },
    // Don't refetch when the window is focused if we have data
    refetchOnWindowFocus: false,
    // Add some stale time to prevent unnecessary refetches
    staleTime: 1000 * 60 * 5, // 5 minutes
  });
};
