import axios, { type AxiosError, type AxiosResponse } from "axios";
import { useMutation } from "@tanstack/react-query";
import type { ResponseErrorInterface } from "../interfaces/ResponseError.interface.ts";
import type { AssessmentInterface } from "../interfaces/assessment.interface.ts";

interface Props {
  candidateId?: string;
  onError: (error: AxiosError<ResponseErrorInterface>) => void;
  onSuccess: () => void;
}

export const useCVAssessmentMutationHook = ({ candidateId, onError, onSuccess }: Props) => {
  return useMutation({
    mutationKey: ["cv_assessment", candidateId],
    mutationFn: async () => {
      const res = await axios<void, AxiosResponse<AssessmentInterface>>({
        data: {},
        url: `/api/candidates/${candidateId}/assessment/trigger/cv_assessment`,
        method: "put",
      });
      return res.data;
    },
    onError: (error: AxiosError<ResponseErrorInterface>) => onError(error),
    onSuccess,
  });
};
